/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Fredoka", sans-serif;
}

body,
html {
  height: 100%;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; /* Stack header and main vertically */
  background-color: #888e72; /* Background color */
  font-family: Verdana, sans-serif;
}

/* Header Styling */
.App-header {
  width: 100%;
  background-color: #888e72; /* Header background color */
  display: flex;
  justify-content: center; /* Center header content horizontally */
  padding: 10px;
}

.App-header img {
  max-width: 100%; /* Ensures the image doesn't overflow */
  height: auto;
  object-fit: cover;
}

/* Main Section Styling */
.Main {
  display: flex;
  flex-direction: column; /* Stack content vertically on smaller screens */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Vertically center the content */
  padding: 20px;
  gap: 20px; /* Add space between the image and text */
}

.Main-content {
  display: flex;
  flex-direction: row; /* Align image and text side by side */
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between image and text */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.Main-image {
  max-width: 40%; /* Ensure the image doesn't take up too much space */
  height: auto;
  border-radius: 10px; /* Optional: Rounded corners for the image */
}

.Main-text {
  color: #e6efe9; /* Text color */
  font-size: 3.5rem;
  max-width: 50%; /* Limit text width for better readability */
  text-align: center; /* Align text for better mobile responsiveness */
}
.text{
  font-size: 5.5rem;
}
/* Carousel Section Styling */
.carousel {
  width: 40%; /* Set carousel width to keep it contained */
  max-width: 700px; /* Prevent it from growing too large on big screens */
  margin: 20px auto; /* Center carousel and add spacing */
  background: #e6efe9; /* Light background for carousel */
  padding: 10px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  height:auto;
}

.carousel img {
  width: 100%; /* Ensure images fill the slide */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Rounded corners for images */
}
.links {
  display: flex;
  gap: 10px; /* Adds a 10px gap between each image */
  justify-content: center; /* Centers the images horizontally */
  flex-wrap: wrap; /* Allows the images to wrap on smaller screens */
}

.links img {
  width: 200px; /* Set the width */
  height: 200px; /* Set the height */
  object-fit: cover; /* Ensures the image fills the container without distortion */
  cursor: pointer; /* Indicates the image is clickable */
  transition: transform 0.3s ease; /* Optional: smooth transition on hover */
}

.links img:hover {
  transform: scale(1.05); /* Optional: Slight zoom effect on hover */
}

.links a {
  display: inline-block; /* Makes sure the anchor tag behaves like a block element */
  text-decoration: none; /* Removes the default underline on links */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .Main-content {
    flex-direction: column; /* Stack image and text vertically */
    gap: 10px; /* Reduce gap for smaller screens */
  }

  .Main-image {
    max-width: 80%; /* Reduce image size for smaller screens */
  }

  .Main-text {
    font-size: 1.7rem; /* Adjust font size for smaller screens */
  }

  .carousel {
    width: 90%; /* Adjust carousel width for smaller screens */
  }
  .links img {
    width: 70px; /* Reduce image size for smaller screens */
    height: 70px; /* Keep the height proportional */
  }
}
